import { BASE_STORE_URL } from "../api/global";

export const staticResource = (link?: string, width?: number, height?: number) => {
  if (!link) return "";

  let _link = link.split(".");
  let ext = _link.pop();

  if (width || height) {
    link = _link.join(".") + `-${width}x${height}.${ext}`;
  } else {
    link = _link.join(".") + `${link === ext ? "" : "."}${ext}`;
  }

  return BASE_STORE_URL + `/${link}`.replace(/\/+/g, "/");
};
