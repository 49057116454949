import dynamic from "next/dynamic";
import css from "./ITooltip.module.scss";
import { Tooltip } from "react-tooltip";
import React, { ReactElement } from "react";
import cn from "classnames";

type ITooltipProps = {
  className?: string;
  text: string;
  position: "top" | "bottom" | "left" | "right";
  button?: ReactElement;
};

export const ITooltip_ = ({ className, text, button, position }: ITooltipProps) => {
  return (
    <div>
      <div
        className={cn(css.tooltipBtn, className)}
        data-tooltip-id="tooltip"
        data-tooltip-content={text}
        data-tooltip-place={position}
      >
        {button || <>&#9432;</>}
      </div>
      <Tooltip
        id="tooltip"
        className={css.tooltip}
        style={{ backgroundColor: "#22364A", color: "#fff" }}
      />
    </div>
  );
};
export const ITooltip = dynamic(() => Promise.resolve(ITooltip_), { ssr: false });
